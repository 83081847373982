body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Light gray background */
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
}

.content {
  max-width: 600px;
  margin-top: 10vh;
}

.welcome-title {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
}

.welcome-content {
  font-size: 1em;
  margin-bottom: 20px;
  color: #333;
  text-align: left;
}

.links-container {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.link-item {
  color: #0073b1;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.link-item span {
  text-decoration: underline;
}

.link-item:hover {
  color: #005582;
}

a {
  color: #0073b1;
}

a:hover {
  color: #005582;
  text-decoration: underline;
}
